<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <custom-table
                    v-if="!refreshData"
                    :tableConfig="tableConfig"
                    :fields="fields"
                    :items="items"
                    @radioButtonTableFilterChange="updateItems"
                    :page="page"
                ></custom-table>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import CustomTable from '@/components/shared/table/CustomTable';
import Spinner from '@/components/shared/element/Spinner';
import PageTitle from '@/layouts/components/PageTitle.vue';
import { INTERVENTION_CLIENT, TABLE } from '@/constants';
import { InterventionRepository } from '@/repositories';
import { EVENTS } from '@/constants/client/events';
import InputFilterEvents from '@/views/client/InputFilterEvents';

export default {
    name: 'ClientInterventionList',
    extends: InputFilterEvents,
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('client.pages.interventionList.title'),
            items: [],
            tableConfig: {
                sortBy: 'created_at',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                displayCount: true,
                currentPage: 1,
                radioButtonsOptions: [
                    {
                        text: this.$t('client.pages.interventionList.filterStatus.inProgress'),
                        value: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.IN_PROGRESS
                    },
                    {
                        text: this.$t('client.pages.interventionList.filterStatus.canceled'),
                        value: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.CANCELED
                    },
                    {
                        text: this.$t('client.pages.interventionList.filterStatus.done'),
                        value: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.DONE
                    },
                    {
                        text: this.$t('client.pages.interventionList.filterStatus.billed'),
                        value: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.BILLED
                    },
                    {
                        text: this.$t('client.pages.interventionList.filterStatus.all'),
                        value: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.ALL
                    }
                ],
                radioButtonSelectedOption: INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.IN_PROGRESS
            },
            fields: [
                {
                    key: 'id',
                    label: this.$t('client.pages.interventionList.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ref_client',
                    label: this.$t('client.pages.interventionList.table.columns.ref'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'created_at',
                    label: this.$t('client.pages.interventionList.table.columns.dateCall'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'site.name',
                    label: this.$t('client.pages.interventionList.table.columns.site'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'reason',
                    label: this.$t('client.pages.interventionList.table.columns.reason'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'status',
                    label: this.$t('client.pages.interventionList.table.columns.status'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'alert_date',
                    label: this.$t('client.pages.interventionList.table.columns.alertDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'show_details',
                    label: this.$t('client.pages.interventionList.table.columns.details'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            refreshData: true,
            page: TABLE.PAGE.CLIENT.INTERVENTION.ID
        };
    },
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            const filters = this.getFilters();
            filters.status = this.tableConfig.radioButtonSelectedOption;
            InterventionRepository.getInterventionList(filters)
                .then(response => {
                    this.refreshData = false;
                    this.items = response.data.data;
                    this.items.map(item => {
                        if (item.status === null) {
                            item.status = '';
                        } else {
                            item.status = this.$t('client.pages.interventionList.interventionStatus.' + item.status);
                        }
                    });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        updateItems(radioButtonSelectedValue) {
            this.tableConfig.radioButtonSelectedOption = radioButtonSelectedValue;
            this.getData();
        }
    }
};
</script>
